import { ReactNode } from "react";

interface BulletedTextListItemProps {
  header: string | ReactNode;
  description: string | ReactNode;
  listStyle?: "check" | "check-circle" | "none";
}

export default function BulletedTextListItem({
  header,
  description,
  listStyle = "check",
}: BulletedTextListItemProps) {
  return (
    <li className="flex mb-3">
      {listStyle === "check" && (
        <div className="items-center pr-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="overflow-hidden mt-1"
            color="#2D494E"
          >
            <path
              d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      )}
      {listStyle === "check-circle" && (
        <div className="items-center pr-4">
          <img
            src="/icons/bullet-point-icon.svg"
            className="mt-2 mr-2 align-middle"
            alt="Bullet point icon"
          />
        </div>
      )}
      <div className="elm-p5 leading-learn-hero-line-height">
        <b>{header}: </b>
        {description}
      </div>
    </li>
  );
}
